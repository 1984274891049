import { graphql, navigate } from 'gatsby';
import { useEffect } from 'react';
import { object } from 'prop-types';
import { Wrap } from '../components/sections-wrap';
import {
  Header, Content, Faqs, Disclaimer,
} from '../components/alimony-calculator';
import {
  coloradoRedirectToTermsAndConditionIfNecessary,
  texasRedirectToTermsAndConditionIfNecessary,
} from '../functions-calculator';
import * as styles from './templates.module.scss';
import { constants } from '../config';

function AlimonyCalculatorTemplate({ data, pageContext }) {
  const { name, calculatorId, calculatorTypeDefault } = pageContext;
  const { contentfulCalculatorInitialPages } = data;
  const { disclaimer } = contentfulCalculatorInitialPages;
  useEffect(() => {
    if (calculatorId === constants.calculatorsIds.coloradoAlimonyCalculator.id) {
      coloradoRedirectToTermsAndConditionIfNecessary(
        navigate,
        contentfulCalculatorInitialPages?.order,
      );
    } else if (calculatorId === constants.calculatorsIds.texasAlimonyCalculator.id) {
      texasRedirectToTermsAndConditionIfNecessary(
        navigate,
        contentfulCalculatorInitialPages?.order,
      );
    }
  }, []);

  return (
    <div className={styles.pageStyles}>
      <Wrap>
        <div className={styles.calculatorContainer}>
          <Header title={name} />
          <Content
            data={contentfulCalculatorInitialPages}
            calculatorId={calculatorId}
            calculatorTypeDefault={calculatorTypeDefault}
            isRichText
          />
          {contentfulCalculatorInitialPages.faqs && (
            <Faqs faqs={contentfulCalculatorInitialPages.faqs} />
          )}
          {disclaimer?.disclaimer
          && (
          <Disclaimer disclaimer={disclaimer?.disclaimer} />
          )}
        </div>
      </Wrap>
    </div>
  );
}

export const query = graphql`
  query ($uri: String){
    contentfulCalculatorInitialPages(uri: { eq: $uri }) {
      title
      uri
      error
      order
      content {
        raw
      }
      firstButtonText
      firstButtonRedirectTo
      secondButtonText
      secondButtonRedirectTo
      faqs {
        question
        answer {
          raw
        }
      }
      help
      calculator___type {
        name
        type
      }
      disclaimer{
        disclaimer
      }
    }
  }
`;

AlimonyCalculatorTemplate.propTypes = {
  data: object,
  pageContext: object,
};

AlimonyCalculatorTemplate.defaultProps = {
  data: {},
  pageContext: {},
};

export default AlimonyCalculatorTemplate;
export { Head } from '../components/seo/seo';
